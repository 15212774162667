import { template as template_bccb819769c14a619a3d2bc41affdf0f } from "@ember/template-compiler";
const FKLabel = template_bccb819769c14a619a3d2bc41affdf0f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
