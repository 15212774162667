import { template as template_fda00a1e773e45a5a5acc3ac814538f1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fda00a1e773e45a5a5acc3ac814538f1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
