import { template as template_a8adf14962ad4be3afaa20b979b4987a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a8adf14962ad4be3afaa20b979b4987a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
