import { template as template_a61bd9d38f6a4d33bcd92caade07060b } from "@ember/template-compiler";
const FKControlMenuContainer = template_a61bd9d38f6a4d33bcd92caade07060b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
